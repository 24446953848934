<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <!-- <el-form-item label="证书名称">
                        <el-select v-model='filters.sel'>
                            <el-option v-for='item in options' :key='item.ID' :label='item.CertificateName' :value='item.ID'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="证书状态">
                        <el-select v-model='filters.selStatus'>
                            <el-option v-for='item in options1' :key='item.ID' :label='item.CertificateName' :value='item.ID'></el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="省:">
                        <el-select 
                        @change="selectRowChange1" 
                        v-model="filters.selecSheng" 
                        ref ='provinceRef'
                        placeholder="请选择" 
                        v-loading='provinceLoading' 
                        :clearable="userInfoByToken.ProvinceID == null ? true : false ">
                            <template v-for="item in shengArr">
                                <el-option
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                    :disabled='provinceIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="市:">
                        <el-select 
                        @change="selectRowChange2" 
                        v-model="filters.selecShi" 
                        placeholder="请选择" 
                        ref ='cityRef'
                        v-loading='cityLoading' 
                        :clearable="userInfoByToken.CityID == null ? true : false">
                            <template v-for="item in shiArr">
                                <el-option                            
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                    :disabled='cityIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区/县:">
                        <el-select 
                        v-model="filters.selecXian" 
                        placeholder="请选择" 
                        ref ='regionRef'
                        v-loading='areaLoading' 
                        :clearable="userInfoByToken.RegionID == null ? true : false">
                            <template v-for="item in xianArr">
                                <el-option                            
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                    :disabled='areaIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="地区:">
                        <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item  label="关键字:">
                        <el-input v-model='filters.name' placeholder="输入单位名称" clearable @clear='handleClear'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            @current-change='currentChange'
            :highlight-current-row='true'>
            <el-table-column type='index' label="序号" width='60'></el-table-column>
            <el-table-column prop="UnitName" label="单位名称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="CertificateRegisterCount" label="证书数量" width="100" align="center"></el-table-column>
            <el-table-column prop="LiablePerson" label="消防责任人" width="100" align="center"></el-table-column>
            <el-table-column prop="LiablePersonPhoneNumber" label="消防责任人手机" width="130" align="center"></el-table-column>
            <el-table-column prop="Administrator" label="消防安全管理人" width="130" align="center"></el-table-column>
            <el-table-column prop="AdministratorPhoneNumber" label="消防安全管理人手机" width="160" align="center"></el-table-column>
            <el-table-column prop="ContactPerson" label="联系人" align="center"></el-table-column>
            <el-table-column prop="ContactPersonPhoneNumber" label="联系人手机" width="130" align="center"></el-table-column>
            <el-table-column prop="Tel" label="固定电话" width="100" align="center"></el-table-column>
            <el-table-column prop="Address" label="地址" align="center"></el-table-column>
            <el-table-column prop="NoticeTime" :formatter="formatCreateTime" label="通知时间" width="100" align="center"></el-table-column>
            <el-table-column prop="NoticeCountStr" label="通知次数" width="100" align="center"></el-table-column>
            <el-table-column fixed="right"
                label="操作"
                width="120"
                align="center">
                <template slot-scope="scope">
                    <el-tag v-if='scope.row.isHandle' type='success'>已处理</el-tag>
                    <el-button v-else @click="handleClick(scope.row)" type="primary" size="mini">处理</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getToBePunishSocialUnitListPage,handleSocialUnit,getAreaDictionaryList } from '@/api/api'
import Qs from 'qs'
export default {
    components:{Toolbar},
    data() {
        return {
            buttonList: [],
            filters:{
                selecSheng: 1919,
                selecShi: '',
                selecXian: '',
                selecAreaArr: [],
                sel:null,
                name:'',
                selStatus: '',
            },
            shengArr: [],
            shiArr: [],
            xianArr: [],
            provinceLoading:false,
            cityLoading:false,
            areaLoading:false,
            provinceIsDisabled:false,
            cityIsDisabled:false,
            areaIsDisabled:false,
            userInfoByToken:{},
            tableData: [],
            options:[],
            options1:[
                {CertificateName:'所有证书',ID:null},
                {CertificateName:'待发证',ID:0},
                {CertificateName:'已发证',ID:1},
                {CertificateName:'已撤销',ID:2},
            ],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getDataList(){
            this.listLoading = true
            var params = {
                certificateHolder:this.filters.name?this.filters.name:null,
                certificateDesignID:this.filters.sel,
                certificateStatus:this.filters.selStatus?this.filters.selStatus:null,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.selecSheng) {
                params.provinceID = this.filters.selecSheng
            }
            if(this.filters.selecAreaArr.length > 0) {
                params.cityID = this.filters.selecAreaArr[0]
            }
            if(this.filters.selecAreaArr.length > 1) {
                params.regionID = this.filters.selecAreaArr[1]
            }
            getToBePunishSocialUnitListPage(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.tableData = result.Response.Data
                }else{
                    this.listLoading = false
                }
            })
        },
        getToBePunishSocialUnitListPage() {
            this.getDataList();
        },
        // 处理
        handleClick(val) {
            this.$confirm('确认处理吗？', '提示', {}).then(() => {
                handleSocialUnit({ID:val.ID}).then(res => {
                    if(res.data.Success){
                        this.$message({
                            message:res.data.Message,
                            type:'success'
                        })
                        this.getDataList()
                    }else{
                        this.$message({
                            message:res.data.Message,
                            type:'error'
                        })
                    }
                })
            }).catch(() => {})
        },
        // 点击table某一行
        currentChange(val) {
            
        },
        // 省选中
        selectRowChange1(row) {
            if(!row){
                this.shiArr = []
                this.xianArr = []
                this.filters.selecShi = ''
                this.filters.selecXian = ''
            }else{
                this.parentSheng = row
                var params = {
                    parentId: this.parentSheng,
                    level:1
                }
                this.getPlace(params)
            }           
        },
        // 市选中
        selectRowChange2(row) {
            if(!row){
                this.xianArr = []
                this.filters.selecXian = ''
            }else{
                this.parentShi = row
                var params = {
                    parentId: this.parentShi,
                    level:2
                }
                this.getPlace(params)
            }          
        },
        // 获取行政区域
        getPlace(params){
            switch(params.level){
                case 0:
                    this.provinceLoading = true
                    break;
                case 1:
                    this.cityLoading = true
                    break;
                case 2:
                    this.areaLoading = true
                    break
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    switch(params.level){
                        case 0:
                            this.provinceLoading = false
                            this.shengArr = res.data.Response.reverse();
                            break;
                        case 1:
                            this.cityLoading = false
                            this.shiArr = res.data.Response.reverse();
                            break;
                        case 2:
                            this.areaLoading = false
                            this.xianArr = res.data.Response.reverse();
                            break
                    }
                }else{return}
            }).catch(() => {})
        },
        // 行政区域选项分页
        pages1Change(value) {
            this.pagesOpt1.pageIndex = value
            var params = {
                level: 0,
            }
            this.getCityPage(params);
        },
        pages1Change2(value) {
            this.pagesOpt2.pageIndex = value
            var params = {
                parentId: this.parentSheng,
            }
            this.getCityPage2(params);
        },
        pages1Change3(value) {
            this.pagesOpt3.pageIndex = value
            var params = {
                parentId: this.parentShi,
            }
            this.getCityPage3(params);
        },
        // 根据首页跳转修改查询条件
        updatedFilterData(data) {
            if(data.provinceID) {
                this.filters.selecSheng = data.provinceID
                var params = {
                    parentId:data.provinceID,
                    level: 1
                }
                this.getPlace(params)
                this.provinceIsDisabled = true
            }
            if(data.cityID) {
                this.filters.selecShi = data.cityID
                var params = {
                    parentId:data.cityID,
                    level: 2
                }
                this.getPlace(params)
                this.cityIsDisabled = true
            }
            if(data.regionID) {
                this.filters.selecXian = data.regionID
            }
            this.getDataList();
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        // 筛选项input框清空
        handleClear(){
            
        },
    },
    created(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    mounted() {
        // var params = {
        //     level: 0,
        // }
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getPlace(params)
        if(this.$route.query.userDataParams) {
            var userDataParams = JSON.parse(this.$route.query.userDataParams)
        } else {
            var userDataParams = ''
        }
        if(userDataParams) {
            this.updatedFilterData(userDataParams)
        } else {
            this.getDataList();
        }
    }
}
</script>

<style lang="stylus" scoped>

</style>